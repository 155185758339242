<template>
  <div class="p-login">
    <header class="p-login-center p-login-header">
      <figure class="p-login-header_logo">
        <a href="https://www.bom.ai">
          <!-- <img alt="" src="//oss.bom.ai/erp-static/img/erp-logo.png"> -->
          <img alt="" src="//oss.bom.ai/erp-static/img/erp-logo-v2.png">
        </a>
      </figure>
      <p class="p-login-header-text">
        <a href="http://erp.bom.ai/erp-01.html">返回首页</a>
        <span class="p-login-header-text_span"> | </span>
        <a  href="http://erp.bom.ai/contact.html" target="_blank">联系我们</a>
      </p>
    </header>
    <article class="p-login-box">
      <section class="p-login-center p-login_content">
        <div class="login-box-left">
          <figure v-if="imgData">
            <a :href="imgData.LinkURL" target="_blank"><img :src="imgData.ImageURL" alt=""></a>
          </figure>
          <span @click="onRefImg" class="login-box-left_icon"><i class="el-icon-refresh-right"></i></span>
        </div>
        <div class="login-box-right">
          <login></login>
        </div>
      </section>
    </article>
    <footer class="p-login-center p-login-footer">
      <section class="p-login-footer_content">
        <div class="p-login-footer_text">
          <p>
            〖深圳总部〗：深圳市福田区华强北街道振兴路桑达工业区418栋曼哈数码广场A栋四楼双创中心东面
          </p>
          <p>
            〖香港〗：九龍觀塘成業街19-21號，成業工業大廈3樓33室 ADDRESS: Unit 33, 3/F.,Shing Yip Industrial Building,No.19-21 Shing Yip
            Street,Kwun Tong,Kowloon.
          </p>
          <p>〖上海〗：上海市静安区天目西路218嘉里不夜城b座1207室</p>
          <p>
            <span>客服QQ：800106568</span>
            <span> 客服电话：400-699-2899</span>
          </p>
        </div>
        <div class="p-login-footer_img">
          <figure><img alt="" src="/static/imgs/header_wechat_qrcode.png"><span style="width: 80px;">官方公众号</span></figure>
          <figure><img alt="" src="/static/imgs/header_qywechat_qrcode.png"><span>正能量微信客服</span></figure>
        </div>
      </section>
      <section class="p-login-footer_info">
        <a href="https://beian.miit.gov.cn/" style="color: rgb(153, 153, 153);">
          ©{{ copyright }} 深圳市正能量网络技术有限公司|粤ICP备17005480号</a>
      </section>
    </footer>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {Login} from "~/views/Login"
import {isClient} from "~/lib/runtime";

export default {
  name: "unAuthLayout",
  components: {
    Login
  },
  async created() {
    this.hideMenu = !!isClient();
    await this.getInfoData()
  },
  data() {
    return {
      hideMenu: false, // 是否隐藏左侧菜单
      imgData: null
    };
  },
  computed: {
    ...mapState(["siderbarHide", "domRect"])
  },
  methods: {
    mouseWheel(e) {
      this.$emit("mouse-wheel", e);
    },

    async getInfoData(FilterId = 0) {
      const data = await this.$post('SysNotices/GetNoticesInfo', {FilterId: FilterId, Type: 3})
      if (data) {
        this.imgData = data
      }
    },

    async onRefImg() {
      const filterId = this.imgData.ID || 0
      await this.getInfoData(filterId)
    }

  },
  mounted() {
    // console.log(this)
  }
};

</script>

<style lang='scss' scoped>
@import "app/assets/styles/variables";
@import "app/assets/styles/mixins";

.p-login {
  width: 100%;
  box-sizing: border-box;
  background: #FFFFFF;
  overflow-y: auto;
  height: 100%;
  // 原始标签样式 start
  figure {
    margin: 0;
    padding: 0;
  }

  // 原始标签样式 end

  // 公用 start
  .p-login-center {
    width: 1280px;
    box-sizing: border-box;
    padding: 0 80px;
    margin: 0 auto;
  }

  // 公用 end

  // 头部
  .p-login-header {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .p-login-header-text {
      vertical-align: middle;
      line-height: 48px;
      color: #999999;
      a {
        color: #999999;
        font-size: 12px;

      }
      .p-login-header-text_span {
        display: inline-block;
        margin: 0 5px;
      }
    }
    .p-login-header_logo {
      img {
        // height: 32px;
        width: 72%;
        height: auto;
      }
    }
  }

  // 头部 end

  // 中心
  .p-login-box {
    width: 100%;
    min-width: 1280px;
    height: 600px;
    background: url("/static/imgs/login_banner.png");

    .p-login_content {
      padding: 0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    .login-box-left {
      //width: 640px;
      height: 430px;
      text-align: right;
      position: relative;

      img {
        height: 430px;
        max-width: 640px;
      }

      .login-box-left_icon {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 16px;
        cursor: pointer;
        width: 20px;
        height: 20px;
        line-height: 20px;
        background: rgba(0, 0, 0, .15);
        text-align: center;
        border-radius: 4px ;
        color: #FFFFFF;
      }
    }


    .login-box-right {
      // width: 580px;
      //-webkit-box-shadow: -6px 0 6px 0 rgba(0, 0, 0, .3);
      //box-shadow: -6px 0 6px 0 rgba(0, 0, 0, .3);
      border-radius: 2px 2px 0 0;
      // background: #fff
    }
  }

  // 中心 end

  // 底部
  .p-login-footer {
    height: 176px;
    padding-top: 24px;
    font-size: 12px;

    .p-login-footer_content {
      display: flex;
      justify-content: space-between;
      color: #333333;
    }

    .p-login-footer_text {
      p:last-child {
        margin-top: 20px;
      }
    }

    .p-login-footer_img {
      display: flex;
      figure {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          text-align-last: justify;
          display: inline-block;

        }
      }
      figure:first-child{
        margin-right: 5px;
      }
      img {
        width: 80px;
        height: 80px;
      }
    }

    .p-login-footer_info {
      text-align: center;
      padding-bottom: 10px;
    }
  }

  // 底部end
}

</style>
